import React from "react";
import "./index.scss";
import ButtonTwo from "../../component/Button";
import calendar from "../../assets/images/calendar.svg";
import { Helmet } from "react-helmet-async";

function cmp() {
    return (
        <section className="contentAll">
            <Helmet>
                <title>
                    Combien coute un controle moto cagnes sur mer , prix d'un
                    controle technique moto cagnes sur mer
                </title>
                <meta
                    name="description"
                    content="Découvrez le prix d’un contrôle technique moto à Cagnes-sur-Mer. Tarifs compétitifs dès 75€ pour motos, scooters , voiture sans permis et quads. Réservez votre contrôle rapidement en ligne"
                />
            </Helmet>{" "}
            <section class="hero-section">
                <h1>Contrôle Technique Moto à Cagnes-sur-Mer</h1>
                <p>
                    Faites votre contrôle technique moto rapidement et au
                    meilleur prix avec nos experts.
                </p>
            </section>
            <section class="services-section">
                <h2>
                    Pourquoi faire votre contrôle technique moto chez nous ?
                </h2>
                <p>
                    Nous offrons un service de contrôle technique pour motos,
                    scooters et quads dans notre centre à Cagnes-sur-Mer. Nos
                    avantages :
                </p>
                <ul>
                    <li>Des experts qualifiés et spécialisés en deux-roues.</li>
                    <li>Des tarifs compétitifs adaptés à tous les budgets.</li>
                    <li>Une prise en charge rapide et efficace.</li>
                    <li>Possibilité de réservation en ligne.</li>
                </ul>
                <br />
            </section>
            <section class="pricing-section">
                <h2>Nos Tarifs</h2>
                <div class="pricing-table">
                    <div class="pricing-item">
                        <h3>Motos standards</h3>
                        <p>
                            À partir de <strong>85€</strong>
                        </p>
                    </div>
                    <div class="pricing-item">
                        <h3>Scooters , Quad et grosses cylindrées</h3>
                        <p>
                            À partir de <strong>85€</strong>
                        </p>
                    </div>
                    <div class="pricing-item">
                        <h3>Contre-visite</h3>
                        <p>
                            <strong>20€</strong>
                        </p>
                    </div>
                </div>
                <div className="buttonRdv"></div>
            </section>
            <section class="how-it-works-section">
                <h2>Comment ça marche ?</h2>
                <ol>
                    <li>
                        Réservez votre rendez-vous en ligne{" "}
                        <strong>(Promotion -10euros)</strong> ou par téléphone.
                    </li>
                    <br />
                    <ButtonTwo
                        class="buttonTwoContentRed"
                        texte="Prendre Rendez vous"
                        image={calendar}
                        link="https://www.cta-cagnes-sur-mer.com/prendre-rdv.html"
                    />
                    <br />
                    <li>
                        Apportez votre moto le jour du rendez-vous avec les
                        documents nécessaires (carte grise, etc.).
                    </li>
                    <li>
                        Recevez votre certificat de contrôle technique en moins
                        de 30 minutes.
                    </li>
                </ol>
                <a href="/Qu'elle-document-fournir" class="secondary-cta">
                    Consultez les documents nécessaires
                </a>
                <br />
            </section>
            <section class="location-contact-section">
                <h2>Où nous trouver ?</h2>
                <p>
                    Notre centre est situé à <strong>Cagnes-sur-Mer</strong>,
                    facilement accessible pour tous les motards de la région.
                </p>

                <h3>Contactez-nous</h3>
                <p>
                    📞 Téléphone : <a href="tel:0493208522">04 93 20 85 22</a>
                </p>
                <p>📍 Adresse : Zac des Travails, 06800 Cagnes-sur-Mer</p>
                <a
                    href="https://www.cta-cagnes-sur-mer.com/contact.html"
                    class="secondary-cta"
                >
                    Envoyez-nous un message
                </a>
            </section>
            <section class="faq-section">
                <h2>Questions Fréquentes</h2>
                <div class="faq-item">
                    <h3>Quels documents dois-je apporter ?</h3>
                    <p>
                        Vous devez fournir la carte grise, une attestation
                        d’assurance, et éventuellement des documents spécifiques
                        en cas d'importation ou de vente.
                    </p>
                </div>
                <div class="faq-item">
                    <h3>Combien de temps dure un contrôle technique moto ?</h3>
                    <p>
                        En moyenne, un contrôle technique moto dure environ 30
                        minutes.
                    </p>
                </div>
                <div class="faq-item">
                    <h3>Que faire en cas de contre-visite ?</h3>
                    <p>
                        Vous pouvez effectuer une contre-visite dans les 2 mois
                        suivant le premier contrôle pour un tarif réduit.
                    </p>
                    <br />
                    <br />
                </div>
            </section>
        </section>
    );
}

export default cmp;
