import React from "react";
import cta from "../../assets/images/cta.jpg";
import { Helmet } from "react-helmet-async";

import "./index.scss";

function Qsn() {
    return (
        <section className="contentCpvc">
            <Helmet>
                <title>cta gueirard qui sommes nous ?</title>
                <meta
                    name="description"
                    content="présentation de la société cta gueirard"
                />
            </Helmet>{" "}
            <h1>Qui sommes nous?</h1>
            <img src={cta} alt="Cta gueirard"></img>
            <p>
                <strong>CTA GUEIRARD</strong>, situé à
                <strong> Cagnes-sur-Mer</strong>, est votre spécialiste local en
                <strong> contrôle technique moto.</strong> Depuis plusieurs
                mois, nous offrons un service <strong>fiable</strong>,
                <strong>rapide</strong> et
                <strong>conforme aux dernières réglementations </strong> pour
                garantir la sécurité de votre véhicule sur la route.
            </p>
            <br></br>
            <p>
                {" "}
                Nos contrôles techniques permettent de s’assurer que votre moto
                respecte <strong>les normes en vigueur</strong>, tout en vous
                offrant des conseils personnalisés pour maintenir votre véhicule
                en parfait état de marche.
            </p>
            <br></br>
            <p>
                Chez <strong>CTA GUEIRARD</strong>, nous avons à cœur de fournir
                un service de proximité à <strong>Cagnes-sur-Mer</strong> et
                dans les environs, en facilitant l’accès au contrôle technique
                moto pour les conducteurs locaux. Nous sommes fiers d’être un
                acteur majeur du contrôle moto à <strong>Cagnes-sur-Mer</strong>
                , alliant expertise technique, professionnalisme, et engagement
                envers la satisfaction client.{" "}
            </p>
            <br></br>
            <p>
                Pourquoi choisir <strong>CTA GUEIRARD</strong> ? Localisation
                pratique : Basés à <strong>Cagnes-sur-Mer</strong>, nous sommes
                idéalement situés pour les habitants et les travailleurs de la
                région souhaitant effectuer un <strong>contrôle moto</strong>{" "}
                rapidement et efficacement.
            </p>
            <br></br>
            <p>
                Nous sommes reconnus pour notre <strong>rigueur</strong> et
                notre
                <strong> professionnalisme</strong> dans le domaine du contrôle
                technique moto à <strong>Cagnes-sur-Mer.</strong> Notre objectif
                est de vous offrir <strong>la tranquillité d'esprit</strong>{" "}
                lorsque vous prenez la route, en sachant que votre moto a été
                inspectée par des controleur qualifiés.
            </p>
        </section>
    );
}

export default Qsn;
