import "../ArticleBulle/index.scss";
import { Link } from "react-router-dom";

function ArticleBulle(props) {
    return (
        <Link to={props.link} className="content">
            <figure>
                <img src={props.image} alt={props.alt} />
                <figcaption className="text-overlay">
                    <h2>{props.title}</h2>
                </figcaption>
            </figure>
        </Link>
    );
};
 


export default ArticleBulle;
