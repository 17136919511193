// src/pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div style={{ textAlign: "center", padding: "50px" }}>
            <h1 style={{ fontSize: "6em", marginBottom: "0.5em" }}>404</h1>
            <p style={{ fontSize: "1.5em", marginBottom: "1em" }}>
                Oups ! La page que vous recherchez n'existe pas.
            </p>
            <Link
                to="/"
                style={{
                    display: "inline-block",
                    padding: "0.5em 1em",
                    fontSize: "1em",
                    color: "#fff",
                    backgroundColor: "#007BFF",
                    textDecoration: "none",
                    borderRadius: "5px",
                }}
            >
                Retour à la page d'accueil
            </Link>
        </div>
    );
};

export default NotFound;
