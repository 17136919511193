import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.scss";
import Header from "./layout/header";
import reportWebVitals from "./reportWebVitals";
import ButtonTwo from "./component/Button";
import calendar from "./assets/images/calendar.svg";
import outil from "./assets/images/outil.jpg";
import ArticleBulle from "./component/ArticleBulle";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import QuizPage from "./pages/quiz"; // Par exemple, si tu as une page de quiz
import Cpvc from "./pages/cpvc/cpvc.jsx";
import NotFound from "./component/notFound";
import cadran from "./assets/images/cadranMoto.jpg";
import pneusMoto from "./assets/images/pneusMoto.jpg";
import Footer from "./layout/footer";
import Papers from "./assets/images/papers.jpg";
import ImageComponent from "./component/TopImages/index.jsx";
import question from "./assets/images/question.jpg";
import Tp from "./pages/tp/index.jsx";
import Qdf from "./pages/qdf/index.jsx";
import Qsn from "./pages/qsn/index.jsx";
import MensionL from "./pages/mentionL/mention.jsx";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet-async";
import Cmp from "./pages/cmp/cmp.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <HelmetProvider>
        <React.StrictMode>
            <Router>
                <Header />
                <Routes>
                    {/* Définis tes routes ici */}

                    <Route path="/quiz" element={<QuizPage />} />
                    <Route
                        path="/Comment-preparer-sont-contrôle-moto"
                        element={<Cpvc />}
                    />
                    <Route
                        path="/Comment-Trouver-ma-taille-de-pneus"
                        element={<Tp />}
                    />
                    <Route path="/Qu'elle-document-fournir" element={<Qdf />} />
                    <Route path="/Qui-sommes-nous" element={<Qsn />} />
                    <Route path="/mention-legale" element={<MensionL />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/controle-moto-prix" element={<Cmp />} />

                    {/* Tu peux aussi ajouter tes sections actuelles directement dans une route */}
                    <Route
                        path=""
                        element={
                            <>
                                <Helmet>
                                    <title>
                                        Controle moto, quad, voiture sans permis
                                        , catégorie L
                                    </title>
                                    <meta
                                        name="description"
                                        content="Effectuez votre contrôle technique moto rapidement et facilement. Découvrez nos services spécialisés pour motos, scooters et deux-roues, conformes aux réglementations en vigueur. Rendez-vous rapide, prix compétitifs et expertise garantie. Prenez rendez-vous dès maintenant !"
                                    />
                                </Helmet>{" "}
                                <section>
                                    <div className="topContent">
                                        <ImageComponent />
                                    </div>

                                    <div className="contentCity">
                                        <h1>Contrôle Technique Moto</h1>
                                        <h2>Cagnes-Sur-Mer</h2>
                                    </div>
                                </section>
                                <section>
                                    <div className="contentBlue">
                                        <ButtonTwo
                                            class="buttonContent"
                                            texte="Prendre Rendez vous"
                                            image={calendar}
                                            link="https://www.cta-cagnes-sur-mer.com/prendre-rdv.html"
                                        />
                                        <ButtonTwo
                                            class="buttonContent"
                                            texte="Préparez votre Moto"
                                            image={outil}
                                            link="/quiz"
                                        />
                                    </div>
                                </section>
                                <section className="contentMiddle">
                                    <div className="contentBubulle">
                                        <ArticleBulle
                                            image={cadran}
                                            alt="éviter la contre visite moto"
                                            title="Comment preparer votre contrôle moto?"
                                            link="/Comment-preparer-sont-contrôle-moto"
                                        />

                                        <ArticleBulle
                                            image={pneusMoto}
                                            alt="Trouver la taille pneus moto"
                                            title="Comment Trouver ma taille de pneus?"
                                            link="/Comment-Trouver-ma-taille-de-pneus?"
                                        />

                                        <ArticleBulle
                                            image={Papers}
                                            alt="Qu'elle document fournir au centre de controle automobile"
                                            title="Qu'elle document fournir?"
                                            link="/Qu'elle-document-fournir"
                                        />
                                    </div>
                                    <div className="divQuestion">
                                        <img
                                            src={question}
                                            alt="controle moto cagne sur mer"
                                        ></img>
                                    </div>
                                </section>
                            </>
                        }
                    />
                </Routes>
                <Footer />
            </Router>
        </React.StrictMode>
    </HelmetProvider>
);

reportWebVitals();
