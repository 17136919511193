import React from "react";
import qdf from "../../assets/images/papers.jpg";
import "./index.scss";
import { Helmet } from "react-helmet-async";

function Cpvc() {
    return (
        <section>
            <div className="contentQdf">
                <Helmet>
                    <title>
                        Qu'elle document fournir pour un controle technique Moto
                        , Quad , Scooter 50 , 125 , voiture sans permis{" "}
                    </title>
                    <meta
                        name="description"
                        content="Préparez votre contrôle technique (CT) facilement : découvrez les documents obligatoires à fournir, comme la carte grise, l'attestation d'assurance et bien plus selon votre véhicule."
                    />
                </Helmet>{" "}
                <h1>
                    Qu'elle document fournir pour un contrôle technique moto? ✅
                </h1>
                <img src={qdf}></img>
                <p>
                    Pour un contrôle de moto, vous devrez généralement fournir
                    certains documents importants afin de prouver la conformité
                    de votre véhicule et d'assurer que tout est en règle.
                </p>
            </div>
            <div className="divContentQdf">
                <h2>Véhicule de plus de 30 ans sans carte grise : </h2>{" "}
                <ul>
                    Attestation délivrée par le constructeur ou son représentant
                    :
                </ul>
                <p>
                    <strong>ou</strong>
                </p>
                <ul>Attestation FFVE</ul>
            </div>
            <div className="divContentQdf">
                <h2>
                    Véhicule L1e mis en circulation avant le 01/07/2024 n'ayant
                    jamais été immatriculé :
                </h2>

                <ul>
                    Duplicata du certificat de conformité délivré par le
                    constructeur ou sont représentant en france
                </ul>
                <p>
                    <strong>ou</strong>
                </p>
                <ul>
                    facture du véhicule comportant au moin le genre , la marque
                    , le type et le numéro d'identification du véhicule
                </ul>

                <p>
                    <strong>ou</strong>
                </p>
                <ul>
                    Attestation d'assurance comportant au moins le genre , la
                    marque le type et le numéro d'indentification du véhicule
                </ul>
            </div>
            <div className="divContentQdf">
                <h2>importation de véhicule :</h2>
                <ul> Carte gris étrangére (Originale)</ul>
                <p>
                    <strong>ou</strong>
                </p>
                <ul>
                    piéce officiel prouvant l'origine de propriété du véhicule
                    visée par les autorités administrative du pays d'origine{" "}
                </ul>
                <p>
                    <strong>ou</strong>
                </p>
                <ul>
                    pieces officiel certifiant que le ci étranger à été retiré
                </ul>
            </div>
            <div className="divContentQdf">
                <h2>Véhicule destinée a une vente aux enchéres publique :</h2>
                <ul>
                    photocopie de la carte grise visée par le commisaire priseur
                    ou l'huisier de justice
                </ul>
            </div>
        </section>
    );
}

export default Cpvc;
