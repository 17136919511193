import { Link } from "react-router-dom";
import "./index.scss";

function ButtonTwo(props) {
    return (
        <Link class={props.class} to={props.link}>
            <p>{props.texte}</p>
            <img src={props.image}></img>
        </Link>
    );
}

export default ButtonTwo;
