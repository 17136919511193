import React from "react";
import Quiz from "../component/quiz/index";

function App() {
    return (
        <div className="App">
            <h1>Preparer votre Contrôle Moto</h1>
            <Quiz />
        </div>
    );
}

export default App;
