import React, { useState } from "react";
import "./index.scss";
import immat from "../../assets/images/immat.jpg";
import ef from "../../assets/images/ef.jpg";
import compteur from "../../assets/images/affich.jpg";
import poigner from "../../assets/images/poign.jpg";
import piedmain from "../../assets/images/piedMain.jpg";
import bulle from "../../assets/images/bulle.jpg";
import tube from "../../assets/images/tube.jpg";
import retro from "../../assets/images/retroviseur.jpg";
import pneus from "../../assets/images/pneus.jpg";
import Button from "../Button/index.jsx";
import calendar from "../../assets/images/calendar.svg";
import clignotant from "../../assets/images/cligno.jpg";
import klaxonne from "../../assets/images/commande.jpg";
import feux from "../../assets/images/feu.jpg";
import suspensionAv from "../../assets/images/suspension.jpg";
import suspensionAr from "../../assets/images/suspensionar.jpg";
import bequille from "../../assets/images/bequille.jpg";
import acceuil from "../../assets/images/5973800.png";
const questions = [
    {
        questionText: "Dimmension plaque d'immatriculation",
        image: immat,
        answerOptions: [
            { answerText: "Oui", isCorrect: true },
            {
                answerText: "Non",
                isCorrect: false,
                explanation:
                    "Les plaque d'immatriculation plus petite sont soumise à contre visite il faudra faire le necessaire",
            },
        ],
    },
    {
        questionText: "Plaque d'immatriculation Etat fixation",
        image: ef, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Oui", isCorrect: true },
            {
                answerText: "Non",
                isCorrect: false,
                explanation:
                    "ne plaque d'immatriculation mal fixée peut effectivement entraîner une contre-visite lors du contrôle technique. Selon les normes en vigueur, la plaque d'immatriculation doit être solidement fixée et clairement lisible. Une fixation défectueuse est considérée comme un défaut car elle peut rendre la plaque illisible ou entraîner sa perte, ce qui représente un risque pour la sécurité et pour l'identification du véhicule.",
            },
        ],
    },
    {
        questionText: "Compteur Kilométrique visible?",
        image: compteur, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Oui", isCorrect: true },
            {
                answerText: "Non",
                isCorrect: false,
                explanation:
                    "un compteur kilométrique non visible peut entraîner une contre-visite lors du contrôle technique. Selon les réglementations en vigueur, le compteur kilométrique doit être lisible afin de permettre le relevé précis du kilométrage du véhicule. Si le compteur est illisible, caché ou non fonctionnel, cela est considéré comme un défaut, car le contrôle du kilométrage est essentiel pour évaluer l'état et l'historique d'usure du véhicule.",
            },
        ],
    },
    {
        questionText: "Etat poigner de frein / embrayage ?",
        image: poigner, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Normal", isCorrect: true },
            {
                answerText: "Plier",
                isCorrect: false,
                explanation:
                    "Oui, des poignées de frein et d'embrayage endommagées peuvent effectivement entraîner une contre-visite lors du contrôle technique. Pour garantir la sécurité du conducteur, les poignées de frein et d’embrayage doivent être en bon état et permettre une utilisation optimale. Si elles sont abîmées, cela peut altérer la capacité de freinage et de contrôle de la moto, ce qui est considéré comme un défaut de sécurité.",
            },
        ],
    },
    {
        questionText: "Feu stop fonctionnelle Pied(Moto) / Mains Gauche,droite",
        image: piedmain, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Oui", isCorrect: true },
            {
                answerText: "Non",
                isCorrect: false,
                explanation:
                    "un feu stop non fonctionnel peut entraîner une contre-visite lors du contrôle technique. Les feux stop sont essentiels pour signaler aux autres usagers de la route que vous freinez, garantissant ainsi la sécurité de tous. Si un feu stop ne fonctionne pas, cela est considéré comme un défaut de sécurité, car il réduit la visibilité de vos intentions de freinage, augmentant le risque d’accident.",
            },
        ],
    },
    {
        questionText: "Etat de la bulle",
        image: bulle, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Oui", isCorrect: true },
            {
                answerText: "Non",
                isCorrect: false,
                explanation:
                    "Une bulle de moto abîmée peut effectivement entraîner une contre-visite lors du contrôle technique si elle présente des fissures importantes, des cassures ou un niveau de dégradation qui altère la visibilité ou la sécurité du conducteur. La bulle joue un rôle crucial en termes de confort et de protection contre le vent et les débris, et un dommage peut compromettre sa fonction protectrice.",
            },
            {
                answerText: "Ne posséde pas",
                isCorrect: true,
            },
        ],
    },
    {
        questionText: "Pot d'échappement",
        image: tube, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Origine", isCorrect: true },
            { answerText: "Non homologué", isCorrect: false },
            {
                answerText: "Pas d'origine homologué",
                isCorrect: true,
                explanation:
                    "Un pot d’échappement non homologué peut effectivement entraîner une contre-visite lors du contrôle technique, en particulier si celui-ci affecte les niveaux d'émissions polluantes ou de bruit. Les pots d’échappement non homologués ne respectent souvent pas les normes d'émissions et de bruit exigées par la réglementation, ce qui peut entraîner un rejet du véhicule au contrôle technique. Pour éviter tout problème, il est conseillé d'utiliser un pot d’échappement homologué conforme aux normes en vigueur.",
            },
        ],
    },

    {
        questionText: "Etat Rétroviseur",
        image: retro, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Bien", isCorrect: true },
            {
                answerText: "Cassé",
                isCorrect: false,
                explanation:
                    "des rétroviseurs en mauvais état peuvent entraîner une contre-visite lors du contrôle technique. Les rétroviseurs sont essentiels pour assurer une visibilité arrière optimale, indispensable à la sécurité du conducteur et des autres usagers de la route. Si les rétroviseurs sont fissurés, endommagés ou présentent une visibilité réduite, cela est considéré comme un défaut.",
            },
        ],
    },
    {
        questionText: "Rétroviseur obligatoire? présent gauche / droite ",
        image: retro, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Oui", isCorrect: true },
            {
                answerText: "Non",
                isCorrect: false,
                explanation:
                    "l'absence de rétroviseurs obligatoires entraîne une contre-visite lors du contrôle technique. Les rétroviseurs sont indispensables pour garantir une visibilité arrière et assurer la sécurité du conducteur et des autres usagers de la route. En fonction du type de véhicule, un ou deux rétroviseurs sont légalement requis. En leur absence, le véhicule n’est pas conforme aux normes de sécurité en vigueur.",
            },
        ],
    },
    {
        questionText: "Pneus Avant",
        image: pneus, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "neuf", isCorrect: true },
            { answerText: "usée", isCorrect: true },
            {
                answerText: "niveau témoin",
                isCorrect: false,
                explanation:
                    "des pneus avant abîmés peuvent entraîner une contre-visite lors du contrôle technique. Les pneus jouent un rôle essentiel dans l'adhérence et la sécurité du véhicule, et leur état est rigoureusement contrôlé. Des fissures, une usure prononcée, ou des déformations sur les pneus avant sont considérés comme des défauts majeurs qui compromettent la sécurité. Des pneus endommagés peuvent diminuer la stabilité du véhicule, surtout en conditions de freinage et sur routes mouillées.",
            },
            {
                answerText: "mort",
                isCorrect: false,
                explanation:
                    "des pneus avant abîmés peuvent entraîner une contre-visite lors du contrôle technique. Les pneus jouent un rôle essentiel dans l'adhérence et la sécurité du véhicule, et leur état est rigoureusement contrôlé. Des fissures, une usure prononcée, ou des déformations sur les pneus avant sont considérés comme des défauts majeurs qui compromettent la sécurité. Des pneus endommagés peuvent diminuer la stabilité du véhicule, surtout en conditions de freinage et sur routes mouillées.",
            },
        ],
    },
    {
        questionText: "Pneus Arriére",
        image: pneus, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "neuf", isCorrect: true },
            { answerText: "usée", isCorrect: true },
            {
                answerText: "au témoin",
                isCorrect: false,
                explanation:
                    "des pneus arriére abîmés peuvent entraîner une contre-visite lors du contrôle technique. Les pneus jouent un rôle essentiel dans l'adhérence et la sécurité du véhicule, et leur état est rigoureusement contrôlé. Des fissures, une usure prononcée, ou des déformations sur les pneus avant sont considérés comme des défauts majeurs qui compromettent la sécurité. Des pneus endommagés peuvent diminuer la stabilité du véhicule, surtout en conditions de freinage et sur routes mouillées",
            },
            {
                answerText: "mort",
                isCorrect: false,
                explanation:
                    "des pneus avant abîmés peuvent entraîner une contre-visite lors du contrôle technique. Les pneus jouent un rôle essentiel dans l'adhérence et la sécurité du véhicule, et leur état est rigoureusement contrôlé. Des fissures, une usure prononcée, ou des déformations sur les pneus avant sont considérés comme des défauts majeurs qui compromettent la sécurité. Des pneus endommagés peuvent diminuer la stabilité du véhicule, surtout en conditions de freinage et sur routes mouillées.",
            },
        ],
    },
    {
        questionText: "Feux indicateur de direction Gauche / droite",
        image: clignotant, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Fonctionne", isCorrect: true },
            {
                answerText: "Fonctionne Pas",
                isCorrect: false,
                explanation:
                    "Si un feu indicateur est cassé, il est important de le faire réparer dès que possible pour assurer la sécurité de votre conduite",
            },
        ],
    },
    {
        questionText: "Le klaxonne fonctionne?",
        image: klaxonne, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Fonctionne", isCorrect: true },
            {
                answerText: "Fonctionne Pas",
                isCorrect: false,
                explanation:
                    "Le klaxon est un élément de sécurité indispensable qui permet de signaler un danger potentiel aux autres usagers de la route. C’est pourquoi le contrôle technique exige que le klaxon fonctionne correctement",
            },
        ],
    },
    {
        questionText:
            "Le bouton feu de détresse fonctionne ? (si d'origine sur le véhicule",
        image: clignotant, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Fonctionne", isCorrect: true },
            {
                answerText: "Fonctionne Pas",
                isCorrect: false,
                explanation:
                    ". Les feux de détresse sont essentiels pour avertir les autres usagers en cas de panne ou de situation d'urgence.",
            },
            { answerText: "Ne posséde pas", isCorrect: true },
        ],
    },
    {
        questionText: "Les feux position, croisement , plein phare fonctionne?",
        image: feux, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Fonctionne", isCorrect: true },
            {
                answerText: "Fonctionne Pas",
                isCorrect: false,
                explanation:
                    "Ce sont des éléments de sécurité essentiels pour assurer une visibilité optimale, aussi bien pour vous que pour les autres usagers. Un dysfonctionnement de l'un de ces feux entraînera une contre-visite obligatoire",
            },
        ],
    },
    {
        questionText: "Présence de fuite au niveau des ammortisseurs?",
        image: suspensionAv, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            {
                answerText: "Oui",
                isCorrect: false,
                explanation:
                    " Les amortisseurs sont essentiels pour la stabilité du véhicule et pour assurer une bonne tenue de route, notamment lors des virages ou des freinages. Une fuite d’huile dans un amortisseur indique que celui-ci ne fonctionne plus correctement et doit être réparé ou remplacé pour garantir la sécurité",
            },
            { answerText: "Non", isCorrect: true },
        ],
    },
    {
        questionText: "Présence de fuite au niveau des ammortisseurs Arriére?",
        image: suspensionAr, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            {
                answerText: "Oui",
                isCorrect: false,
                explanation:
                    " Les amortisseurs sont essentiels pour la stabilité du véhicule et pour assurer une bonne tenue de route, notamment lors des virages ou des freinages. Une fuite d’huile dans un amortisseur indique que celui-ci ne fonctionne plus correctement et doit être réparé ou remplacé pour garantir la sécurité",
            },
            { answerText: "Non", isCorrect: true },
        ],
    },
    {
        questionText: "Etat de la béquille",
        image: bequille, // Remplacez par l'URL de l'image réelle
        answerOptions: [
            { answerText: "Bonne", isCorrect: true },
            {
                answerText: "Cassé",
                isCorrect: false,
                explanation:
                    ". En France, pour les contrôles techniques obligatoires des deux-roues, une béquille en mauvais état, qui ne maintient pas correctement le véhicule ou présente des signes de faiblesse, pourrait être un motif de contre-visite",
            },
        ],
    },
];

export default function Quiz() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [wrongAnswers, setWrongAnswers] = useState([]);

    const handleAnswerOptionClick = (isCorrect, answerText, explanation) => {
        if (isCorrect) {
            setScore(score + 1);
        } else {
            const currentQuestionData = questions[currentQuestion];
            setWrongAnswers((prevState) => [
                ...prevState,
                {
                    question: currentQuestionData.questionText,
                    incorrectAnswer: answerText,
                    correctAnswer: currentQuestionData.answerOptions.find(
                        (option) => option.isCorrect
                    ).answerText,
                    explanation: explanation, // Ajoutez l'explication ici
                },
            ]);
        }

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            setCurrentQuestion(nextQuestion);
        } else {
            setShowScore(true);
        }
    };

    return (
        <div className="app">
            {showScore ? (
                <div className="score-section">
                    <h2>
                        Vous avez marqué {score} sur {questions.length}
                    </h2>

                    {score === questions.length ? (
                        <div className="congratulation">
                            <h3>
                                Félicitations ! Vous avez répondu correctement à
                                toutes les questions ! 🎉
                            </h3>

                            <Button
                                texte="Prendre Rendez vous"
                                image={calendar}
                                link="https://www.cta-cagnes-sur-mer.com/prendre-rdv.html"
                            />
                        </div>
                    ) : (
                        <>
                            {wrongAnswers.length > 0 && (
                                <div className="centerButton">
                                    <h2>
                                        Des modifications sont nécessaires suite
                                        aux résultats des tests.
                                    </h2>

                                    <h3>Voici les réponses incorrectes :</h3>
                                    <ul>
                                        {wrongAnswers.map((item, index) => (
                                            <li key={index}>
                                                <strong>Question :</strong>{" "}
                                                {item.question}
                                                <br />
                                                <strong>
                                                    Votre réponse :
                                                </strong>{" "}
                                                {item.incorrectAnswer}
                                                <br />
                                                <strong>
                                                    Bonne réponse :
                                                </strong>{" "}
                                                {item.correctAnswer}
                                                <br />
                                                <strong>
                                                    Explication :
                                                </strong>{" "}
                                                {item.explanation}
                                            </li>
                                        ))}
                                    </ul>
                                    <Button
                                        texte="Acceuil"
                                        image={acceuil}
                                        link="/"
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div className="question-section">
                        <div className="question-count">
                            <span>Question {currentQuestion + 1}</span>/
                            {questions.length}
                        </div>
                        <div className="question-text">
                            {questions[currentQuestion].questionText}
                        </div>
                        {questions[currentQuestion].image && (
                            <div className="question-image">
                                <img
                                    src={questions[currentQuestion].image}
                                    alt="Question visual"
                                />
                            </div>
                        )}
                    </div>
                    <div className="answer-section">
                        {questions[currentQuestion].answerOptions.map(
                            (answerOption, index) => (
                                <button
                                    onClick={() =>
                                        handleAnswerOptionClick(
                                            answerOption.isCorrect,
                                            answerOption.answerText,
                                            answerOption.explanation // Passez l'explication ici
                                        )
                                    }
                                    key={index}
                                >
                                    {answerOption.answerText}
                                </button>
                            )
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
