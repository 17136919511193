import React from "react";
import "./index.scss";
import pneus from "./pneusMotoArticle.webp";

function Cpvc() {
    return (
        <section>
            <div className="contentTp">
                <h1>Comment trouver ma taille de pneus? ✅</h1>
                <img src={pneus}></img>
                <p>
                    Pour trouver la taille de pneus adaptés à votre moto sur le
                    site de Michelin, voici un guide rapide en plusieurs étapes
                    :
                </p>
            </div>
            <section className="contentBotTp">
                <h2>Étape 1 : Accéder au site de Michelin</h2>

                <p>
                    Rendez-vous sur le site officiel de Michelin{" "}
                    <a href="https://www.michelin.fr/motorbike/find-your-motorcyle-tyres-online?gad_source=1&gclid=Cj0KCQjw99e4BhDiARIsAISE7P-pvDmDf8m8RwFRnM4y52lz61EagHZtaOtyeI7WbL8ps-tApzynmEEaAnBiEALw_wcB&gclsrc=aw.ds">
                        lien
                    </a>{" "}
                </p>

                <h2>Étape 2 : Aller dans la section "Pneus Moto"</h2>
                <p>
                    Dans le menu principal ou la page d'accueil, cherchez
                    l'onglet ou le lien qui renvoie vers la section{" "}
                    <strong>"Pneus Moto"</strong> . Il est souvent directement
                    accessible sous la rubrique "Nos produits" ou "Pneus".
                </p>

                <h2>Étape 3 : Utiliser le "Sélecteur de Pneus"</h2>
                <p>
                    Michelin propose un{" "}
                    <strong>outil de recherche de pneus spécifique.</strong>{" "}
                    Vous devrez entrer des informations sur votre moto pour
                    obtenir la taille de pneus adéquate.
                </p>
                <ul>
                    <strong>Marque de la moto :</strong> Sélectionnez la marque
                    de votre moto dans le menu déroulant.
                </ul>
                <ul>
                    <strong>Modèle de la moto :</strong> Choisissez ensuite le
                    modèle exact de votre véhicule.
                </ul>
                <ul>
                    <strong>Cylindrée et année de fabrication :</strong>{" "}
                    Complétez avec la cylindrée et l’année de production pour
                    affiner la recherche.
                </ul>

                <h2>Étape 4 : Consulter les résultats</h2>
                <p>
                    L’outil affichera ensuite une{" "}
                    <strong>liste de pneus compatibles</strong> avec votre moto,
                    en précisant <strong>les tailles avant et arrière.</strong>{" "}
                    Vous trouverez aussi des recommandations en fonction de
                    l'usage (route, sport, off-road, etc.).
                </p>
            </section>
        </section>
    );
}

export default Cpvc;
