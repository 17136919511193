import "./index.scss";


function Footer() {
    return (
        <div className="contentFooter">
            <div className="footerContentTopDiv">
                <div className="footerTopDiv">
                    <i className="fa-solid fa-envelope"></i>
                    <p>
                        Nous contacter par émail
                        <br />
                        Gueirard.frederic@gmail.com
                    </p>
                </div>
                <div className="footerTopDiv">
                    <i className="fa-solid fa-route"></i>
                    <p>
                        Autosecurite Zac des travails :
                        <br />
                        Zac des travails , 06800
                        <br />
                        Cagnes-sur-mer, France
                    </p>
                </div>
                <div className="footerTopDiv">
                    <i class="fa-solid fa-phone-volume"></i>
                    <p>
                        Nous contacter par telephone : <br /> 04.93.20.85.22
                    </p>
                </div>
            </div>

            <div className="footerDiv">
                <div className="footerBottomContent">
                    <h2>Liens Utiles</h2>
                    <a href="/Qui-sommes-nous">Qui sommes nous?</a>
                    <a href="https://www.cta-cagnes-sur-mer.com/contact.html">
                        Contact
                    </a>
                    <a href="Mention-legale">Mention légale</a>
                    <a href="controle-moto-prix">Nos Tarifs</a>
                </div>
                <div>
                    <h2>Nos services</h2>

                    <li>
                        <a href="https://www.cta-cagnes-sur-mer.com/rdv-controle-technique-moto-voiture-sans-permis.html">
                            Contrôle Moto cagnes 50 cm3
                        </a>
                    </li>
                    <li>
                        <a href="https://www.cta-cagnes-sur-mer.com/rdv-controle-technique-moto-voiture-sans-permis.html">
                            Contrôle Moto cagnes 125 cm3
                        </a>
                    </li>
                    <li>
                        <a href="https://www.cta-cagnes-sur-mer.com/rdv-controle-technique-moto-voiture-sans-permis.html">
                            Contrôle Quad
                        </a>
                    </li>
                    <li>
                        <a href="https://www.cta-cagnes-sur-mer.com/rdv-controle-technique-moto-voiture-sans-permis.html">
                            Contrôle Voiture Sans Permis
                        </a>
                    </li>
                    <li>
                        <a href="https://www.cta-cagnes-sur-mer.com/rdv-controle-technique-moto-voiture-sans-permis.html">
                            Contre-visite Moto
                        </a>
                    </li>
                    <li>
                        <a href="https://www.cta-cagnes-sur-mer.com/rdv-controle-technique-moto-voiture-sans-permis.html">
                            Contre-volontaire Moto
                        </a>
                    </li>
                </div>
                <div>
                    <h2>Horaires</h2>

                    <li>Lundi: 8:00 - 18:00</li>
                    <li>Mardi: 8:00 - 18:00</li>
                    <li>Mercredi: 8:00 - 18:00</li>
                    <li>Jeudi: 8:00 - 18:00</li>
                    <li>Vendredi: 8:00 - 18:00</li>
                    <li>Samedi: Fermé</li>
                    <li>Dimanche: Fermé</li>
                </div>
                <div>
                    <h2>A propos</h2>

                    <li>Zac des travails</li>
                    <li>Tel : 04.93.20.85.22</li>
                    <li>06800 Cagnes sur mer</li>
                </div>
            </div>
        </div>
    );
}

export default Footer;
