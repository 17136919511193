import React from "react";
import check from "../../assets/images/check.webp";
import "./index.scss";
import { Helmet } from "react-helmet-async";

function Cpvc() {
    return (
        <section className="contentSectionCp">
            <Helmet>
                <title>
                    Comment préparer votre contrôle technique moto : Guide
                    complet
                </title>
                <meta
                    name="description"
                    content="Préparez votre contrôle technique moto avec notre guide complet. Conseils pratiques, vérifications essentielles et astuces pour éviter la contre-visite."
                />
            </Helmet>{" "}
            <h1>
                Comment préparer votre contrôle technique moto : Guide complet
                ✅
            </h1>
            <img src={check}></img>
            <p>
                Le contrôle technique moto est une obligation depuis le
                <strong> 17/04/2024</strong> pour assurer la sécurité sur la
                route et maintenir votre moto en bon état de fonctionnement.
            </p>
            <div className="divContent">
                <h2>1. Vérification des pneus et des jantes 🛞 </h2>
                <p>
                    <strong>
                        Les pneus sont essentiels pour la sécurité et le
                        contrôle de votre moto.
                    </strong>
                </p>
                <ul>
                    <br></br>Avant le contrôle, assurez-vous que: la Profondeur
                    des sculptures :
                    <strong>
                        La profondeur légale minimale est de 1.5 mm.
                    </strong>
                </ul>
                <ul>
                    <strong>Pression des pneus: </strong> ajustez la pression de
                    vos pneus en fonction des recommandations du
                    <strong> fabricant</strong>. Un pneu sous-gonflé ou
                    sur-gonflé peut entraîner une défaillance au contrôle.
                </ul>
                <ul>
                    <strong>Usure régulière :</strong> Vérifiez qu’il n’y a pas
                    d’usure anormale, de fissures ou de bosses sur les flancs
                    des pneus. Jantes : Assurez-vous que les jantes ne
                    présentent pas de fissures ou de déformations. Elles doivent
                    être en bon état.
                </ul>
            </div>
            <div className="divContent">
                <h2>2. Vérification des Freins</h2>
                <p>
                    <strong>
                        Les freins sont bien entendu un point crucial pour le
                        contrôle technique. Voici ce qu’il faut vérifier :
                    </strong>
                </p>
                <ul>
                    <strong>Plaquettes et disques :</strong> Vérifiez que les
                    plaquettes de frein ne sont pas trop usées. La limite
                    d’usure est souvent marquée par un indicateur sur les
                    plaquettes.
                </ul>
                <ul>
                    <strong>Liquide de frein : </strong>Le liquide de frein doit
                    être à un niveau suffisant et en bon état. Si le liquide
                    semble vieux ou si la dernière vidange date de plus de 2
                    ans, pensez à le remplacer.
                </ul>
                <ul>
                    <strong>Frein à main (si présent) :</strong> Pour les motos
                    équipées d’un frein à main, assurez-vous qu’il fonctionne
                    correctement et qu’il ne présente pas de jeu excessif.
                </ul>
            </div>
            <div className="divContent">
                <h2>3. Systèmes d’éclairage et signalisation 💡</h2>
                <p>
                    <strong>
                        L’éclairage et la signalisation de la moto sont des
                        aspects incontournables du contrôle technique. Tous les
                        feux doivent fonctionner correctement :
                    </strong>
                </p>
                <ul>
                    <strong>Feux avant et arrière :</strong> Vérifiez que les
                    feux de croisement, feux de route, feux de position avant et
                    arrière fonctionnent correctement.
                </ul>
                <ul>
                    <strong>Clignotants :</strong> Assurez-vous que les
                    clignotants fonctionnent tous et que leurs ampoules ne sont
                    pas grillées.
                </ul>
                <ul>
                    <strong>Feu stop :</strong> Le feu stop doit s’allumer dès
                    que vous actionnez les freins (avant et arrière).
                </ul>
                <ul>
                    <strong>Plaque d’immatriculation :</strong> Vérifiez que
                    l’éclairage de la plaque fonctionne et que la plaque est
                    lisible et bien fixée.
                </ul>
            </div>
            <div className="divContent">
                <h2>4. Système de direction </h2>
                <p>
                    La direction est un autre point clé à vérifier avant de
                    passer le contrôle technique. Assurez-vous que :
                </p>
                <ul>
                    Le guidon tourne librement sans accroc ou jeu excessif. Si
                    vous sentez des points durs en tournant le guidon, cela peut
                    indiquer un problème de roulement de direction.
                </ul>
                <ul>
                    Le jeu de la colonne de direction est minimal. Une colonne
                    de direction trop lâche ou trop serrée peut entraîner une
                    défaillance au contrôle.
                </ul>
            </div>
            <div className="divContent">
                <h2>5. Suspension</h2>
                <p>
                    <strong>
                        Les suspensions garantissent le confort de conduite et
                        la sécurité, elles sont donc également vérifiées lors du
                        contrôle :
                    </strong>
                </p>
                <ul>
                    <strong>Amortisseurs avant et arrière :</strong> Il ne doit
                    pas y avoir de fuite d’huile autour des suspensions. Testez
                    la suspension avant en appuyant fortement sur le guidon, et
                    arrière en appuyant sur la selle : elles doivent réagir de
                    façon fluide, sans rebonds excessifs.
                </ul>
                <ul>
                    <strong>Jeu des roulements :</strong> Vérifiez qu’il n’y a
                    pas de jeu dans les roulements de roues ou dans les
                    suspensions.
                </ul>
            </div>
            <div className="divContent">
                <h2>6. État général de la moto</h2>
                <p>
                    <strong>
                        l’état général de votre moto sera également évalué :
                    </strong>
                </p>
                <ul>
                    <strong>Cadre :</strong> Il doit être en bon état, sans
                    fissures, ni signes de rouille excessive.
                </ul>
                <ul>
                    <strong>Rétroviseurs :</strong> Vérifiez qu’ils sont bien
                    fixés et qu’ils offrent une bonne visibilité.
                </ul>
                <ul>
                    <strong>Selle :</strong> Elle doit être bien fixée et ne pas
                    présenter de déchirures importantes.
                </ul>
                <ul>
                    <strong>Carénage :</strong> Les carénages et protections
                    doivent être en bon état et bien fixés pour éviter tout
                    risque de chute.
                </ul>
            </div>
            <div className="divContent">
                <h2>7. Système de transmission ⚙️</h2>
                <p>
                    <strong>
                        La transmission est une autre partie essentielle du bon
                        fonctionnement de la moto. Voici ce qu’il faut vérifier
                        :
                    </strong>
                </p>
                <ul>
                    <strong>Chaîne :</strong> Elle doit être bien tendue,
                    lubrifiée, et ne pas être usée. Une chaîne trop lâche ou
                    trop tendue peut être dangereuse.
                </ul>
                <ul>
                    <strong>Pignon et couronne :</strong> Vérifiez qu’ils ne
                    sont pas trop usés. Si les dents sont pointues ou déformées,
                    c’est signe qu’il est temps de les changer.
                </ul>
            </div>
            <div className="divContent">
                <h2>8. Échappement et bruit Le niveau sonore de votre .</h2>
                <p>
                    <strong>
                        Un échappement trop bruyant peut entraîner un échec du
                        contrôle (à partir de mars 2025)
                    </strong>
                </p>
                <ul>
                    <strong>Pot d’échappement :</strong> Vérifiez qu’il n’est
                    pas percé ou endommagé. S’il a été modifié ou remplacé par
                    un modèle non homologué, vous risquez un refus au contrôle
                    technique.
                </ul>
                <ul>
                    <strong>Niveau sonore :</strong> Si vous avez un doute sur
                    le bruit de votre moto, faites-la tester dans un centre
                    avant le contrôle. Un pot trop bruyant pourrait poser
                    problème.
                </ul>
            </div>
        </section>
    );
}

export default Cpvc;
