import React, { useState, useEffect } from "react";
import imgSmal from "../../assets/images/gonz.jpg";
import imgDesktop from "../../assets/images/desktopimg.jpg";

const ImageComponent = () => {
    const [imageSrc, setImageSrc] = useState(imgSmal); // Initialisation avec l'image small

    const updateImage = () => {
        const width = window.innerWidth;
        if (width < 431) {
            setImageSrc(imgSmal); // Mise à jour avec l'image small si la largeur est inférieure à 430px
        } else if (width >= 768) {
            setImageSrc(imgDesktop); // Mise à jour avec l'image desktop si la largeur est supérieure ou égale à 768px
        }
    };

    useEffect(() => {
        updateImage(); // Appel initial pour définir l'image
        window.addEventListener("resize", updateImage); // Met à jour lors du redimensionnement

        // Nettoyage de l'event listener lors du démontage du composant
        return () => window.removeEventListener("resize", updateImage);
    }, []);

    return <img src={imageSrc} alt="controle technique moto cagnes sur mer" />;
};

export default ImageComponent;
