import React from "react";
import "./index.scss";
function mentionL() {
    return (
        <section className="contentMention">
            <h2>informations éditeur</h2>
            <p>
                Nom : SARL CTA Gueirard Capital social : 400 euros RCS : Antibes
                B 790 114 144 TVA intracommunautaire : FR04 790 114 144 Activité
                : Contrôle technique automobile - 7120A Siège social : Chemin
                des Travails - ZA des Travails , 06800 Cagnes-sur-Mer SIRET :
                790 114 144 00039 Directeur de la publication : M Frederic
                Gueirard
            </p>

            <h2> Informations légales</h2>
            <p>
                L’éditeur s'engage à ce que le traitement des données
                personnelles effectué sur ce site soit conforme au règlement
                général de protection des données (RGPD) entré en application le
                25 mai 2018 et à la loi Informatique et Libertés de 1978. Le
                présent site ainsi que les traitements de données s'y rapportant
                (newsletter, contact, statistiques) ont été déclarés à
                l'inventaire RGPD en conformité de suivi CNIL sous le numéro
                2021/0859.
            </p>

            <h2> Informations collectées</h2>
            <p>
                Dans le cadre de notre activité, nous collectons et traitons des
                informations dont certaines sont qualifiées de "données
                personnelles". Deux types de données sont susceptibles d’être
                recueillies : - les données transmises directement Ces données
                sont celles que vous nous fournissez directement quand vous
                effectuez une demande de rendez-vous par téléphone, mail,
                formulaire de contact ou de réservation en ligne sur notre site
                Internet et nos plateformes partenaires. Il s’agit de vos nom,
                prénom, adresse postale, adresse email, numéro de fixe ou de
                portable, modèle de véhicule et plaque d’immatriculation. - les
                données collectées automatiquement Lors de vos visites sur notre
                site Internet, une fois votre consentement donné, nous pouvons
                recueillir des informations de type « web analytics » relatives
                à votre navigation, la durée de votre consultation, votre
                adresse IP, votre type et version de navigateur. La technologie
                utilisée est le cookie.
            </p>

            <h2>Utilisation et traitement des données</h2>
            <p>
                Les données que vous nous transmettez directement sont utilisées
                afin de gérer vos rendez-vous, vous re-contacter, vous envoyer
                des relances courriers, SMS ou emails et vous proposer de nous
                donner votre avis sur nos services. Les données « web analytics
                » sont collectées sous forme anonyme (en enregistrant des
                adresses IP anonymes), et nous permettent de mesurer l’audience
                de notre site web, les consultations et les éventuelles erreurs
                afin d’améliorer constamment l’expérience des utilisateurs. En
                savoir plus sur Google Search.
            </p>

            <h2> Conception et réalisation </h2>
            <p>
                Conception et réalisation CTA GUEIRARD site réalisé avec React
            </p>
        </section>
    );
}

export default mentionL;
